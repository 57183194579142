import { UPDATE_CART_DATA, ADD_CART_DATA, DELETE_CART_DATA, REMOVE_CART_DATA } from './ActionTypes';

export function updateCartData(data) {
  return {
    type: UPDATE_CART_DATA,
    data,
  };
}

export function AddCartData(data) {
  return {
    type: ADD_CART_DATA,
    data,
  };
}

export function DeleteCartData(data) {
  return {
    type: DELETE_CART_DATA,
    data,
  };
}

export function RemoveCartData() {
  return {
    type: REMOVE_CART_DATA,
  };
}