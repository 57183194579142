import React, { useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, Typography } from '@mui/material'
import { Add, Delete, PriorityHigh, Remove, ShoppingCart } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import { updateCartData } from 'app/redux/Actions/ActionCreator';
import colors from 'app/styles/colors';
import { RemoveSpace } from 'app/utils';
import useAuth from 'app/hooks/useAuth';
import Images from 'assets/Images';

const mapStateToProps = (state) => {
  return {
    items: state.items
  }
}

function ProductCard(props) {

  const { product } = props

  const { user } = useAuth();
  const dispatch = useDispatch();
  const addToCart = (_data) => dispatch(updateCartData(_data));

  const addToCartHandler = (item) => {
    let obj = {
      type: 'add',
      id: item.ProductID,
      name: item.ProductName,
      image: item.ImageURl,
      qty: item.Qty,
      price: item.SalePrice,
      total: item.Qty * item.SalePrice,
      TaxPercent: item.Qty * item.TaxPercent,
      ShipmentPrice: item.Qty * item.ShipmentPrice,
    };
    addToCart(obj);
  };

  const incrementHandler = (item) => {
    let obj = {
      type: 'increment',
      id: item.ProductID,
    };
    addToCart(obj);
  };

  const decrementHandler = (item) => {
    let obj = {
      type: 'decrement',
      id: item.ProductID,
    };
    addToCart(obj);
  };

  return (
    <Card
      sx={{
        border: '2px solid #eee',
        borderRadius: 2.5,
        position: 'relative',
        '&:hover': {
          borderColor: colors.primary,
          transition: '0.3s ease-in-out',
        },
      }}
    >
      {product.InStock &&
        <CardMedia
          component="img"
          height="70"
          image={Images.soldOut}
          alt="sold out"
          sx={{
            position: 'absolute',
            width: 'auto',
          }}
        />
      }
      <Link to={`/order/${RemoveSpace(product.Category)}/${RemoveSpace(product.ProductName)}/${product.ProductID}`} >
        <CardMedia
          component="img"
          height="150"
          image={product.ImageURl}
          alt="product"
          sx={{ objectFit: 'contain' }}
        />
      </Link>
      <CardContent sx={{ pb: 1 }} >
        <Typography
          variant="caption"
          component={Link}
          to={`/order/${RemoveSpace(product.Category)}/${RemoveSpace(product.ProductName)}/${product.ProductID}`}
          sx={{
            color: colors.textPrimary,
            textDecoration: 'none',
            mb: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            '&:hover': {
              color: colors.primary,
              transition: '0.3s ease-in-out',
            },
          }}
        >
          {product.ProductName}
        </Typography>
        <Grid container spacing={0} sx={{ mb: 1.3, }} >
          <Grid item md={4}>
            <Typography variant='body1' color={colors.primary}>
              $ {user ? product.SalePrice : product.OpenPrice}
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography variant="body2" color={colors.primary} sx={{ textAlign: 'center', }} >
              {/* {token ? '250gx24' : '250g'} */}
              {product.PackSize}{product.UnitCode}
            </Typography>
          </Grid>
        </Grid>
        {!user &&
          <Grid container spacing={0}>
            <Grid item md={12} sx={{ textAlign: 'center', mb: 1.3 }}>
              <Typography
                component={Link}
                to="/sign-in"
                color="error"
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  '&:hover': {
                    color: colors.primary,
                    transition: '0.3s ease-in-out',
                  },
                }}
              >
                SignIn for bulk price
              </Typography>
            </Grid>
          </Grid>
        }

        {/* <Grid container spacing={0}>
          <Grid item md={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '3px 5px',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: colors.primaryMedium,
                marginBottom: '10px',
              }}
            >
              <Remove
                onClick={() => { decreaseQuantity() }}
                sx={{
                  width: '20px',
                  verticalAlign: 'bottom',
                  cursor: 'pointer',
                  '&:hover': {
                    color: colors.primary,
                    transition: '0.3s ease-in-out',
                  },
                }}
              />
              <Typography variant='body1' component="span">{quantity}</Typography>
              <Add
                onClick={() => { increaseQuantity() }}
                sx={{
                  width: '20px',
                  verticalAlign: 'bottom',
                  cursor: 'pointer',
                  '&:hover': {
                    color: colors.primary,
                    transition: '0.3s ease-in-out',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid> */}

        <Button
          variant="contained"
          disabled={product.InStock ? true : false}
          startIcon={product.InStock ? '' : <ShoppingCart />}
          onClick={() => addToCartHandler(product)}
          sx={{ width: '100%' }}
        >
          {product.InStock ? (
            'Out of Stock'
          ) : (
            'Add to Cart'
          )}
        </Button>

        {/* {quantity.length === 0 ? (
          `${console.log('file: Product.js => line 170 => Product => quantity', quantity)}`,
          <Button
            variant="contained"
            onClick={() => addToCart(product)}
            startIcon={<ShoppingCart />}
            sx={{ width: '100%' }}
          >
            Add to Cart
          </Button>
        ) : (
          <Fragment>
            {quantity[index].Qty === 0 ? (
              // `${console.log('file: Product.js => line 170 => Product => quantity', quantity[index].Qty)}`,
              <Button
                variant="contained"
                onClick={() => addToCart(product)}
                startIcon={<ShoppingCart />}
                sx={{ width: '100%' }}
              >
                Add to Cart
              </Button>
            ) : (
              <Grid container spacing={0} >
                <Grid item md={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0px',
                      borderRadius: '8px',
                      border: '1px solid',
                      borderColor: colors.primaryMedium,
                    }}
                  >
                    <IconButton size="small"
                      onClick={() => { decrement(product) }}
                      sx={{
                        bgcolor: colors.primary,
                        borderRadius: '4px',
                        color: colors.white,
                        '&:hover': {
                          bgcolor: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    >
                      {quantity[index].Qty === 1 ? (
                        <Delete fontSize="small" />
                      ) : (
                        <Remove fontSize="small" />
                      )}
                    </IconButton>
                    <Typography variant='body1' component="span" sx={{ pt: '3px' }} >{quantity[index].Qty}</Typography>
                    <IconButton size="small"
                      onClick={() => { increment(product) }}
                      sx={{
                        bgcolor: colors.primary,
                        borderRadius: '4px',
                        color: colors.white,
                        '&:hover': {
                          bgcolor: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Fragment>
        )} */}

        {/* } */}

        {/* {item.soldOut &&
          <Button
            variant="outlined"
            color="error"
            disableRipple
            disableFocusRipple
            disableElevation
            startIcon={<PriorityHigh />}
            sx={{
              width: '100%',
              cursor: 'inherit'
            }}
          >
            Sold Out
          </Button>
        } */}

      </CardContent>
    </Card >
  );
}

export default connect(mapStateToProps)(ProductCard);