import React from 'react';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination"

import Images from 'assets/Images';

const useStyles = makeStyles({
  slider: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: 312,
    borderRadius: 10,
  },
});

function Banner() {

  const classes = useStyles();

  // *Create Banner
  const banner = [
    { image: Images.banner1 },
    { image: Images.banner2 },
    { image: Images.banner3 },
    { image: Images.banner4 },
  ]

  return (
    <Swiper
      className="mySwiper"
      loop={true}
      speed={1000}
      pagination={{
        "dynamicBullets": true,
        "clickable": true,
      }}
      autoplay={{
        "delay": 2500,
        "disableOnInteraction": false,
      }}
    >
      {banner.map((item, index) => (
        <SwiperSlide
          key={index}
          className={classes.slider}
          style={{
            backgroundImage: `url(${item.image})`,
          }}
        >
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Banner; 