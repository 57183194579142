import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container, Box, Divider, IconButton, Grid, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, DataGrid } from '@mui/material';
import { Visibility } from '@mui/icons-material';

import colors from 'app/styles/colors';
import { Service } from 'app/config/service';
import { getDate } from 'app/utils';
import useAuth from 'app/hooks/useAuth';

// *Import Components
import SideNav from 'app/components/SideNav/SideNav';

function MyOrder() {

  const { user } = useAuth()

  const tableHeading = ['Order Id', 'SOID', 'Order Date', 'Status', 'Total', 'Action']

  const [order, setOrder] = useState([]);

  // *For Get Order 
  const getOrder = async () => {
    try {
      const { Data } = await Service.getOrder(user);
      setOrder(Data)
    } catch (error) {
      console.log('file: MyAccount.js => line 52 => getProfile => error', error)
    }
  };

  useEffect(() => {
    getOrder()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>
      <Box sx={{ mb: 10 }}>
        <Grid container spacing={3} sx={{ py: 4, }}>
          <Grid item xs={3}>
            <SideNav path="/my-order" />
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ p: '5px 0px 10px 20px', borderRadius: 2.5, bgcolor: colors.white, }}>

              <Typography variant="h5">My Order</Typography>
              <Divider sx={{ width: '100%', my: 2 }} />

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {tableHeading.map((title, index) => (
                        <TableCell key={index} sx={{ bgcolor: colors.primary, color: colors.white, textAlign: 'center' }}>
                          {title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          {item.OrderID}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          {item.SOID}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          {getDate(item.OrderDate)}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          {item.StatusTitle}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          {item.TotalAmount}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                          <IconButton component={Link} to={`/my-order/${item.OrderID}`} color="primary">
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default MyOrder;