import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Container, Divider, FormControl, Grid, InputLabel, Select, MenuItem, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'

import { emailRegex } from 'app/utils/index';
import { Service } from 'app/config/service';
import { Error } from 'app/components/UI/Error';

function SignUp() {

  const navigate = useNavigate()

  // *For Form Validation
  const { register, formState: { errors }, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false);

  // *For State
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  // *For Get State
  const getState = async () => {
    try {
      const { Data } = await Service.getState()
      setState(Data)
    } catch (error) {
      console.log('file: SignUp.js => line 58 => getState => error', error)
    }
  }

  // *For User Sign Up
  const userSignUp = async (data) => {
    setLoading(true)
    try {
      let obj = {
        CompanyName: data.companyName,
        FullName: data.fullName,
        Address: data.address,
        Address2: data.addressLine2,
        Phone: data.phone,
        Country: data.country,
        City: data.city,
        State: data.state,
        PostalCode: data.postalCode,
        Email: data.email,
        Password: data.password,
        ConfirmPassword: data.confirmPassword,
      }
      const { Message } = await Service.signUp(obj);
      Swal.fire({
        title: 'Success!',
        text: Message,
        icon: 'success',
        confirmButtonText: 'ok'
      }).then(() => {
        navigate('/order');
      });
    } catch (error) {
      console.log('file: SignUp.js => line 30 => userSignUp => error', error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getState()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4, mb: 3 }} >
        Create Your Account
      </Typography>
      <Box sx={{ mt: 1, mb: 12, py: 6, px: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2 }}>
        <Typography component="form" onSubmit={handleSubmit(userSignUp)}>
          <Grid container spacing={0}>
            <Grid item md={7}>
              <Typography variant="h6" sx={{ mb: 1 }}>Company Detail</Typography>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Company Name"
                      error={errors?.companyName?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("companyName", {
                        required: 'Company Name is required',
                      })}
                    />
                    {errors?.companyName?.message && (
                      <Error message={errors?.companyName?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Full Name"
                      error={errors?.fullName?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("fullName", {
                        required: 'Full Name is required',
                      })}
                    />
                    {errors?.fullName?.message && (
                      <Error message={errors?.fullName?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} >
                <Grid item md={12}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Address"
                      error={errors?.address?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("address", {
                        required: 'Address is required',
                      })}
                    />
                    {errors?.address?.message && (
                      <Error message={errors?.address?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Address Line2"
                      sx={{ width: '100%' }}
                      {...register("addressLine2")}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} >
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Phone"
                      error={errors?.phone?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("phone", {
                        required: 'Phone is required',
                      })}
                    />
                    {errors?.phone?.message && (
                      <Error message={errors?.phone?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Country"
                      value="Canada"
                      error={errors?.country?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("country")}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    {errors?.country?.message && (
                      <Error message={errors?.country?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} >
                <Grid item md={4}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="City"
                      error={errors?.city?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("city", {
                        required: 'City is required',
                      })}
                    />
                    {errors?.city?.message && (
                      <Error message={errors?.city?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl size="small" sx={{ width: '100%', my: 1.3 }} >
                    <InputLabel>State</InputLabel>
                    <Select
                      size="small"
                      label="Age"
                      value={selectedState}
                      error={errors?.state?.message && (true)}
                      {...register("state", {
                        required: 'State is required',
                      })}
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      {state.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                    {errors?.state?.message && (
                      <Error message={errors?.state?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Postal Code"
                      error={errors?.postalCode?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("postalCode", {
                        required: 'Postal Code is required',
                      })}
                    />
                    {errors?.postalCode?.message && (
                      <Error message={errors?.postalCode?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Grid item md={4.5}>
              <Typography variant="h6" sx={{ mb: 1 }}>Sign Up</Typography>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                <TextField
                  size="small"
                  variant="outlined"
                  type="email"
                  label="Email"
                  error={errors?.email?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("email", {
                    required: 'Email is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Please enter a valid email address',
                    }
                  })}
                />
                {errors?.email?.message && (
                  <Error message={errors?.email?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                <TextField
                  size="small"
                  variant="outlined"
                  type="password"
                  label="Password"
                  error={errors?.password?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("password", {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters"
                    }
                  })}
                />
                {errors?.password?.message && (
                  <Error message={errors?.password?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                <TextField
                  size="small"
                  variant="outlined"
                  type="password"
                  label="Confirm Password"
                  error={errors?.confirmPassword?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("confirmPassword", {
                    required: 'Confirm Password is required',
                    validate: value =>
                      value === password.current || "Confirm password does not match"
                  })}
                />
                {errors?.confirmPassword?.message && (
                  <Error message={errors?.confirmPassword?.message} />
                )}
              </FormControl>
              <Typography sx={{ fontSize: 12, textAlign: 'right', my: 1 }} >Already member?&nbsp;
                <Typography
                  component={Link}
                  to="/sign-in"
                  color="primary"
                  sx={{
                    fontSize: 12,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                      transition: '0.3s ease-in-out',
                    },
                  }}
                >
                  SignIn
                </Typography>
                &nbsp;here.
              </Typography>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                sx={{ width: '100%', mt: 1, mr: 1 }}
              >
                Sign Up
              </LoadingButton>
            </Grid>
          </Grid>
        </Typography>
      </Box>
    </Container>
  );
}

export default SignUp; 