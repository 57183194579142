import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from 'app/styles/colors';
import Images from 'assets/Images';
import { CurrentYear } from 'app/utils';

const useStyles = makeStyles({
  rootLink: {
    fontSize: 14,
    padding: '8px 0px',
    color: colors.textPrimary,
    marginRight: 5,
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      color: colors.primary,
      transition: '0.3s ease-in',
    },
  },
  link: {
    color: colors.primary,
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

function Footer() {

  const classes = useStyles();

  const footer = [
    {
      title: 'Customer Service',
      list: [
        { title: 'Service & Warranty', link: '/' },
        { title: 'Return & Exchange', link: '/' },
        { title: 'Secured Online Payments', link: '/' },
        { title: 'Cash on Delivery', link: '/' },
        { title: 'Shipping & Delivery', link: '/' },
      ]
    },
    {
      title: 'About Us',
      list: [
        { title: 'About Prime Food', link: '/' },
        { title: 'Newsletter', link: '/' },
        { title: 'News & Press Releases', link: '/' },
      ]
    },
    {
      title: 'Help & Support',
      list: [
        { title: 'Sitemap', link: '/' },
        { title: 'Privacy Policy', link: '/' },
        { title: 'Anti-Fraud Disclaimer', link: '/' },
        { title: 'Terms & Conditions', link: '/' },
        { title: 'Contact Us', link: '/' },
      ]
    },
  ]

  return (
    <Fragment>
      <Box component="footer"
        sx={{
          pt: 4,
          pb: 2.5,
          bgcolor: 'rgba(14, 90, 167,0.1)',
        }}
      >
        <Container>
          <Grid container spacing={2}>

            <Grid item md={3}>
              <Typography
                component="img"
                src={Images.Logo}
                alt="prime-food"
                sx={{
                  width: 'auto',
                  height: 40,
                }}
              />
            </Grid>

            {footer.map((item, index) => (
              <Grid key={index} item md={3}>
                <Typography
                  color={colors.primary}
                  sx={{
                    fontSize: 22,
                    fontWeight: 500,
                    mb: 2,
                  }}
                >
                  {item.title}
                </Typography>
                {item.list.map((list, index) => (
                  <Link
                    key={index}
                    to={list.link}
                    className={classes.rootLink}
                  >
                    {list.title}
                  </Link>
                ))}
              </Grid>
            ))}

          </Grid>
        </Container>
      </Box>
      <Box sx={{ py: 1.3 }}>
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Typography variant="caption" component="p" sx={{ textAlign: 'center' }} >
              © <CurrentYear /> All Rights Reserved. Development by
              <Link to="https://www.mangotechsolutions.com/" target="_blank" className={classes.link}> MangotechSolutions</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}

export default Footer;