const colors = {
    primary: '#03c4eb',
    primaryMedium: ' rgba(3, 196, 235,0.5)',
    primaryLight: 'rgba(3, 196, 235,0.1)',
    secondary: '#27ae00',
    secondaryMedium: 'rgba(39, 174, 0,0.7)',
    backgroundPrimary: '#DCE1F7',
    backgroundInput: '#DBE3F9',
    textPrimary: '#333',
    textSecondary: '#A1989B',
    white: '#fff',
    black: '#000',
    gray: '#ddd',
    lightGray: '#f4f4f4ad',
    darkGray: '#aaa',
    danger: '#AC1520'
};

export default colors;