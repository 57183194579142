import { UPDATE_CART_DATA, ADD_CART_DATA, DELETE_CART_DATA, REMOVE_CART_DATA } from '../Actions/ActionTypes';

let cart = {
  items: [],
  totalAmount: 0,
};

function reducer(state = cart, action) {

  switch (action.type) {
    case UPDATE_CART_DATA:
      let alreadyPresent = false;
      let updatedItems = [...state.items];

      for (const itemIndex in state.items) {
        const element = state.items[itemIndex];
        let alreadyPresentObject = { ...updatedItems[itemIndex].name };
        delete alreadyPresentObject.qty;
        delete alreadyPresentObject.total;
        let newObject = { ...action.data.name };
        delete newObject.qty;
        delete newObject.total;

        if (element.id === action.data.id && JSON.stringify(alreadyPresentObject) === JSON.stringify(newObject)) {
          alreadyPresent = parseInt(itemIndex);
        }
      }
      if (alreadyPresent !== false) {
        if (action.data.type === 'increment') {
          updatedItems[alreadyPresent] = {
            ...updatedItems[alreadyPresent],
            qty: updatedItems[alreadyPresent].qty + (action.data.qty ? action.data.qty : 1),
          };
          updatedItems[alreadyPresent] = {
            ...updatedItems[alreadyPresent],
            total: updatedItems[alreadyPresent].qty * updatedItems[alreadyPresent].price,
          };
        } else if (action.data.type === 'decrement') {
          if (updatedItems[alreadyPresent].qty === 1) {
            updatedItems.splice(alreadyPresent, 1);
            if (updatedItems === undefined) {
              updatedItems = [];
            }
          } else {
            delete updatedItems[alreadyPresent].type;
            updatedItems[alreadyPresent] = {
              ...updatedItems[alreadyPresent],
              qty: updatedItems[alreadyPresent].qty - 1,
            };
            updatedItems[alreadyPresent] = {
              ...updatedItems[alreadyPresent],
              total: updatedItems[alreadyPresent].qty * updatedItems[alreadyPresent].price,
            };
          }
        } else if (action.data.type === 'add') {
          updatedItems[alreadyPresent] = {
            ...updatedItems[alreadyPresent],
            qty: parseInt(updatedItems[alreadyPresent].qty) + parseInt(action.data.qty ? action.data.qty : 1),
          };
          updatedItems[alreadyPresent] = {
            ...updatedItems[alreadyPresent],
            total: updatedItems[alreadyPresent].qty * updatedItems[alreadyPresent].price,
          };
        }
      } else if (action.data.type !== 'decrement') {
        updatedItems = [...updatedItems, { ...action.data, qty: action.data.qty ? action.data.qty : 1 }];
      }
      return {
        ...state,
        items: [...updatedItems],
      };

    case ADD_CART_DATA:
      for (const itemsIndex in state.items) {
        const element = state.items[itemsIndex];
      }
      return {
        ...state,
        items: [...state.items, action.data],
      };

    case DELETE_CART_DATA:
      let new_items = state.items.filter(item => action.data.id !== item.id)
      return {
        ...state,
        items: new_items,
      };

    case REMOVE_CART_DATA:
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
}

export default reducer;