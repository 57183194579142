import React, { Fragment, useState } from 'react'
import { Typography, Grid, AccordionSummary, Accordion, AccordionDetails, FormGroup, FormControlLabel, Checkbox, Divider, Button, Box, ListItem, List, ListItemIcon, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore, Inbox, StarBorder } from '@mui/icons-material';
import colors from 'app/styles/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  filterBox: {
    margin: '8px 0px',
    padding: '8px 0px',
    borderBottom: `1px solid ${colors.gray}`,
    '&:nth-last-child': {
      border: '0px',
    },
  },
});

function Filter(props) {

  const { brand, category, filter } = props
  const classes = useStyles()

  const [checked, setChecked] = useState([]);
  const [expand, setExpand] = useState([]);
  const [subCategoryChecked, setSubCategoryChecked] = useState([]);

  // *For Checkbox
  const handleChecked = (type, value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let isChecked;

    if (currentIndex === -1) {
      newChecked.push(value);
      isChecked = true
    } else {
      newChecked.splice(currentIndex, 1);
      isChecked = false
    }

    setChecked(newChecked);
    filter(type, value, isChecked)
  };

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  return (
    <Fragment>
      <Grid item md={3}>

        <Typography color="textSecondary" sx={{ fontSize: 25, fontWeight: 400 }}>
          FILTERS
        </Typography>
        <Divider />
        <Box className={classes.filterBox}>
          <Button variant="contained" disableRipple sx={{ cursor: 'text', textTransform: 'capitalize', width: 1, '&:hover': { bgcolor: colors.primary } }} >
            Brands
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {brand.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => { handleChecked('brand', item.BrandID) }} dense>
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(item.BrandID) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.BrandTitle} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className={classes.filterBox}>
          <Button variant="contained" disableRipple sx={{ cursor: 'text', textTransform: 'capitalize', width: 1, '&:hover': { bgcolor: colors.primary } }} >
            Categories
          </Button>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {category.map((item, index) => (
              <Fragment>
                <ListItem key={index} disablePadding  >
                  <ListItemButton disableRipple dense onClick={() => { handleChecked('category', item.CategoryID) }} sx={{ display: 'block', width: '42px' }} >
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(item.CategoryID) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemButton>
                  <ListItemButton disableRipple onClick={() => handleCollapse(item.CategoryID)} sx={{ pl: '6px', flex: '100%' }}>
                    <ListItemText primary={item.Category} sx={{ fontSize: '12px' }} />
                    {expand.indexOf(item.CategoryID) !== -1 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                {item.SubCategories.length > 0 &&
                  <Fragment>
                    {item.SubCategories.map((subItem, i) => (
                      <Collapse key={i} in={expand.indexOf(item.CategoryID) !== -1 ? true : false} timeout="auto" unmountOnExit>
                        <List>
                          <ListItemButton disableRipple dense onClick={() => { handleChecked('category', subItem.SubCategoryID) }} sx={{ p: '8px 16px 8px 32px' }} >
                            <ListItemIcon sx={{ minWidth: 'auto' }}>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(subItem.SubCategoryID) !== -1}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={subItem.SubCategory} />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    ))}
                  </Fragment>
                }
              </Fragment>
            ))}
          </List>
        </Box>

      </Grid>
    </Fragment >
  );
}

export default Filter;