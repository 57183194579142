import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Container, Box, Divider, Grid, Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Download, Print } from '@mui/icons-material';

import { Service } from 'app/config/service';
import colors from 'app/styles/colors';

// *Import Components
import SideNav from 'app/components/SideNav/SideNav';
import useAuth from 'app/hooks/useAuth';
import { getDate } from 'app/utils';

function OrderDetail() {

  const { id } = useParams()
  const { user } = useAuth()

  const [orderInvoice, setOrderInvoice] = useState();

  const getOrderInvoice = async () => {
    try {
      const { Data } = await Service.getOrderById(id, user)
      setOrderInvoice(Data[0])
      console.log('file: OrderDetail.js => line 33 => orderDetail => Data', Data)
    } catch (error) {
      console.log('file: OrderDetail.js => line 31 => orderdetail => error', error)
    }
  }

  useEffect(() => {
    getOrderInvoice()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>
      <Box sx={{ mb: 10 }}>
        <Grid container spacing={3} sx={{ py: 4, }}>
          <Grid item xs={3}>

            <SideNav path="/my-order" />

            <Box sx={{ my: 3, p: 1.5, bgcolor: colors.white, boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', }} >
              <Typography variant="h4" color="primary" sx={{ textAlign: 'center', py: 1, borderBottom: `1px solid ${colors.gray}` }}>$ {orderInvoice?.TotalAmount}</Typography>
              <Grid container spacing={1} sx={{ my: 2 }}>
                <Grid item md={7}>
                  <Button variant="contained" startIcon={<Download />} sx={{ width: '100%' }}>Download</Button>
                </Grid>
                <Grid item md={5}>
                  <Button variant="contained" startIcon={<Print />} sx={{ width: '100%' }}>Print</Button>
                </Grid>
              </Grid>
            </Box>

          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                p: '5px 0px 10px 20px',
                borderRadius: 2.5,
                bgcolor: colors.white,
              }}>

              <Typography variant="h5">Order Detail</Typography>
              <Divider sx={{ width: '100%', my: 2 }} />

              <Box sx={{ display: 'flex', my: 3 }}>
                <Typography variant="body1" color="textPrimary" sx={{ flex: 1 }}><b>Order Id:</b> {orderInvoice?.OrderID}</Typography>
                <Typography variant="body1" color="textPrimary" sx={{ flex: 1, textAlign: 'right' }}><b>Order Date:</b> {getDate(orderInvoice?.OrderDate)}</Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}><b>Invoicing and Shipping Address:</b></Typography>
                <Typography variant="body2" color="textSecondary">{orderInvoice?.Address}</Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}><b>Invoice:</b></Typography>
                <Typography variant="body2" sx={{ color: colors.danger }}>Nd465156</Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}><b>Delivery Orders:</b></Typography>
                <Typography variant="body2" sx={{ color: colors.danger }}>Nd465156</Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}><b>Status:</b></Typography>
                <Typography variant="body2" sx={{ color: colors.danger }}>{orderInvoice?.StatusTitle}</Typography>
              </Box>
              <Box sx={{ mt: 8, mb: 2 }}>
                <TableContainer component={Paper} sx={{ boxShadow: 'none', }}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white }}>
                          Sr.No
                        </TableCell>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white }}>
                          Products
                        </TableCell>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white, textAlign: 'right' }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white, textAlign: 'right' }}>
                          Unit Price
                        </TableCell>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white, textAlign: 'right' }}>
                          Taxes
                        </TableCell>
                        <TableCell sx={{ bgcolor: colors.primary, color: colors.white, textAlign: 'right' }}>
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderInvoice?.OrderDetails.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.ProductName}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'right' }}>
                            {item.Qty}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'right' }}>
                            {item.SalePrice}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'right' }}>
                            {item.TaxAmount}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'right' }}>
                            $ {item.SubTotal}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item md={5} sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', my: 1, p: 1 }}>
                    <Typography variant="body1" color="textPrimary" sx={{ flex: 1 }}><b>SubTotal</b></Typography>
                    <Typography variant="body1" color="textPrimary" sx={{ flex: 1, textAlign: 'right' }}>$ {orderInvoice?.TotalAmount}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', my: 1, p: 1 }}>
                    <Typography variant="body1" color="textPrimary" sx={{ flex: 1 }}><b>Total</b></Typography>
                    <Typography variant="body1" color="textPrimary" sx={{ flex: 1, textAlign: 'right' }}>$ {orderInvoice?.TotalAmount}</Typography>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default OrderDetail;