import React, { useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { connect } from 'react-redux';

// *Import Components
import CheckoutStepper from 'app/components/CheckoutStepper/CheckoutStepper';
import OrderSummary from 'app/components/OrderSummary/OrderSummary';

const mapStateToProps = (state) => {
  return {
    items: state.items,
    totalAmount: state.totalAmount
  }
}

function Checkout(props) {

  const { items } = props

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      <Grid container spacing={2} sx={{ py: 4, mb: 6 }}>

        {/* ========== Checkout Detail ========== */}
        <Grid item md={7}>
          <CheckoutStepper items={items} />
        </Grid>

        {/* ========== Order Summary ========== */}
        <Grid item md={5}>
          <OrderSummary items={items} />
        </Grid>

      </Grid>

    </Container>
  );
}

export default connect(mapStateToProps)(Checkout);