import { Navigate } from 'react-router-dom';

// *Import Components
import Home from 'app/views/Home/Home';
import Order from 'app/views/Order/Order';
import About from 'app/views/About/About';
import Contact from 'app/views/Contact/Contact';
import MyCart from 'app/views/MyCart/MyCart';
import Checkout from 'app/views/Checkout/Checkout';
import ProductDetail from 'app/views/ProductDetail/ProductDetail';
import NotFound from 'app/views/NotFound/NotFound';
import MyProfile from 'app/views/MyProfile/MyProfile';
import MyOrder from 'app/views/MyOrder/MyOrder';
import OrderDetail from 'app/views/OrderDetail/OrderDetail';

export const AppRoutes = [
  {
    path: "/",
    component: <Home />
  },
  {
    path: "/order",
    component: <Order />
  },
  {
    path: "/about",
    component: <About />
  },
  {
    path: "/contact",
    component: <Contact />
  },
  {
    path: "/my-cart",
    component: <MyCart />
  },
  {
    path: "/order/:category/:name/:id",
    component: <ProductDetail />
  },
  {
    path: "/checkout",
    component: <Checkout />
  },
  {
    path: "*",
    component: <Navigate to="/404" />
  },
  {
    path: "/404",
    component: <NotFound />
  },
]

export const PrivateRoutes = [
  {
    path: "/my-profile",
    component: <MyProfile />,
  },
  {
    path: "/my-order",
    component: <MyOrder />,
  },
  {
    path: "/my-order/:id",
    component: <OrderDetail />,
  },
]