import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material';

import { Service } from 'app/config/service';
import colors from 'app/styles/colors';

// *Import Components
import Banner from 'app/components/Banners/Banner';
import Category from 'app/components/Categories/Category';
import Brand from 'app/components/Brands/Brand';
import Product from 'app/components/Cards/ProductCard';
import Slider from 'app/components/Sliders/Slider';

function Home() {

  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);

  const getAllCategories = async () => {
    try {
      const { Data } = await Service.getAllCategories()
      setCategory(Data)
    } catch (error) {
      console.log('file: Home.js => line 73 => getCategory => error', error)
    }
  }

  const getProduct = async () => {
    try {
      const brandId = ''
      const catId = ''
      const pageNumber = 1
      const user = localStorage.getItem('jwt')

      const { Data } = await Service.getProduct(brandId, catId, pageNumber, user);
      setProduct(Data)
    } catch (error) {
      console.log('file: Order.js => line 58 => getProduct => error', error)
    }
  }

  const getBrand = async () => {
    try {
      const { Data } = await Service.getBrand()
      setBrand(Data)
    } catch (error) {
      console.log('file: Home.js => line 59 => getBrand => error', error)
    }
  }

  useEffect(() => {
    getAllCategories()
    getProduct()
    getBrand()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      {/* ========== Carousel ========== */}
      <Banner />

      {/* ========== Category ========== */}
      <Box sx={{ pt: 4, pb: 2.5 }}>
        <Slider
          type='category'
          product={category}
          slideView={6}
          spaceBetween={5}
          speed={2000}
          delay={2000}
          pagination={true}
        />
      </Box>

      {/* ========== Deals ========== */}
      <Box sx={{ py: 2.5 }}>

        <Typography variant="h4" component="h4"
          color={colors.textPrimary}
          sx={{
            fontWeight: 600,
            my: 2,
          }}
        >
          Products
        </Typography>

        <Slider
          type='product'
          product={product}
          slideView={5}
          spaceBetween={30}
          speed={2000}
          delay={2500}
          pagination={true}
        />

      </Box>

      {/* ========== Brands ========== */}
      <Box sx={{ py: 2.5 }}>

        <Typography variant="h4" component="h4"
          color={colors.textPrimary}
          sx={{
            fontWeight: 600,
            my: 2,
          }}
        >
          Brands we carry
        </Typography>

        <Slider
          type='brand'
          product={brand}
          slideView={6}
          spaceBetween={5}
          speed={2000}
          delay={2000}
          pagination={true}
        />

      </Box>

    </Container>
  );
}

export default Home;