import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

// *Import Components
import { AppRoutes, PrivateRoutes } from 'app/routes/routes';
import useAuth from './hooks/useAuth';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import SignUp from './auth/SignUp/SignUp';
import SignIn from './auth/SignIn/SignIn';

const theme = createTheme({
  palette: {
    primary: {
      main: '#03c4eb',
      contrastText: '#fff',
    },
    secondary: {
      main: '#27ae00',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Gotham',
    ].join(','),
  },
});

function App() {

  const { user, verifyToken } = useAuth();

  useEffect(() => {
    verifyToken();
  })

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Header ========== */}
        <Header />

        {/* ========== App Routes ========== */}
        <Routes>
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
          <Route element={!user ? <Outlet /> : <Navigate to={-1} />} >
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Route>
          <Route element={user ? <Outlet /> : <Navigate to="/sign-in" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>

        {/* ========== Footer ========== */}
        <Footer />

      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
