import { useState } from "react";

function useProvideAuth() {

  const [user, setUser] = useState(null);

  const signIn = (data) => {
    setUser(data);
  };

  const signOut = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    localStorage.removeItem('accountId')
    setUser(null)
  };

  const verifyToken = () => {
    try {
      const token = localStorage.getItem('jwt');
      setUser(token)
    } catch (error) {
      console.log('file: useProvideAuth.js => line 26 => verifyToken => error', error)
    }
  };

  return {
    user,
    signIn,
    signOut,
    verifyToken
  };
}

export default useProvideAuth;