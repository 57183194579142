import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Avatar, Badge, Box, Button, Container, Divider, Drawer, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { Description, Logout, Person, ShoppingCart } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';

import colors from 'app/styles/colors';
import Images from 'assets/Images';
import useAuth from 'app/hooks/useAuth';

const useStyles = makeStyles({
  link: {
    padding: '10px 15px',
    color: colors.textPrimary,
    marginRight: 5,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: colors.primary,
      transition: '0.3s ease-in',
    },
  },
});

const mapStateToProps = (state) => {
  return {
    items: state.items
  }
}

function Header(props) {

  const { items } = props

  const classes = useStyles()
  const { user, signOut } = useAuth()

  const [mobileMenu, setMobileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuToggle = () => {
    setMobileMenu(!mobileMenu);
  };

  // *Create Menu Link
  const menuList = [
    { label: 'Home', path: '/' },
    { label: 'Order', path: '/order' },
    { label: 'About', path: '/about' },
    { label: 'Contact', path: '/contact' },
  ]

  const dropDown = (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '.MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem component={Link} to="/my-profile" sx={{ my: 1 }}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        My Profile
      </MenuItem>
      <MenuItem component={Link} to="/my-order" sx={{ my: 1 }}>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        My Order
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => { signOut() }}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        SignOut
      </MenuItem>
    </Menu >
  );

  const menu = (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {menuList.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={classes.link}
          >
            {item.label}
          </Link>
        ))}
        {user ? (
          <IconButton
            onClick={handleClick}
            size="small"
            aria-haspopup="true"
          >
            <Avatar sx={{ width: 32, height: 32, }} />
          </IconButton>
        ) : (
          <Fragment>
            <Link
              to="/sign-in"
              className={classes.link}
            >
              Sign In
            </Link>
            <Button variant="contained" size='small'
              component={Link}
              to="/sign-up"
              sx={{
                height: 40,
                textTransform: 'capitalize',
              }}
            >
              Sign Up
            </Button>
          </Fragment>
        )}
        <IconButton
          component={Link}
          to="/my-cart"
          size="small"
          aria-haspopup="true"
          sx={{ ml: 1 }}
        >
          <Badge badgeContent={items.length} color="error">
            <ShoppingCart />
          </Badge>
        </IconButton>
        {dropDown}
      </Box >
    </Fragment>
  );

  return (
    <Typography component="header" sx={{ borderTop: `3px solid ${colors.primary}`, mb: 3 }}>
      <Container>

        <Typography
          component="nav"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 1.2,
            boxShadow: '0 26px 15px -14px #00000059',
          }}
        >

          <Link to="/">
            <Typography
              component="img"
              src={Images.Logo}
              alt="prime-food"
              sx={{
                width: 'auto',
                height: 36,
                mr: 5,
              }}
            />
          </Link>

          <Hidden only={['xs', 'sm']}>
            {menu}
          </Hidden>

          <Hidden only={['md', 'lg', 'xl']}>

            <IconButton color="inherit" edge="end" onClick={menuToggle}>
              <Menu />
            </IconButton>

            <Drawer
              variant="persistent"
              open={mobileMenu}
              onClose={menuToggle}
              sx={{ width: '70%' }}
              ModalProps={{ keepMounted: true }}
            >
              {menu}
            </Drawer>

          </Hidden>

        </Typography>

      </Container>
    </Typography>
  );
}

export default connect(mapStateToProps)(Header);