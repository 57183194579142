import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';

import colors from 'app/styles/colors';

function OrderSummary(props) {

  const { items } = props
  console.log('file: OrderSummary.js => line 11 => OrderSummary => items', items)

  const { pathname } = useLocation()

  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(false);
  const [taxPrice, setTaxPrice] = useState(0);
  const [shipmentPrice, setShipmentPrice] = useState(0);

  let productAmount = 0;
  let tax = 0;

  const getSubTotal = () => {
    if (items.length > 0) {
      items.forEach(value => {
        const price = value.price * value.qty
        productAmount += price
        setSubTotal(productAmount)
      });
    }
  }

  const getTaxPrice = () => {
    if (items.length > 0) {
      items.forEach(value => {
        const price = value.price * value.TaxPercent
        console.log('file: OrderSummary.js => line 36 => getTaxPrice => price', price)
        const taxTotal = price / 100
        tax += taxTotal
        setTaxPrice(tax)
      });
    }
  }

  const getShipmentPrice = () => {
    if (items.length > 0) {
      items.forEach(value => {
        setShipmentPrice(value.ShipmentPrice)
      });
    }
  }

  useEffect(() => {
    getSubTotal()
    getTaxPrice()
    getShipmentPrice()
  }, [items]);

  return (
    <Box sx={{ bgcolor: colors.lightGray, borderRadius: 2.5, p: 2 }} >
      <Typography variant="h5" color={colors.textPrimary}>Order Summary</Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Box sx={{ display: 'flex', my: 2 }}>
        <Typography variant="body1" sx={{ flex: 'auto' }} >Subtotal</Typography>
        <Typography variant="body1" align="right">
          $ {subTotal}
        </Typography>
      </Box>
      {pathname === '/checkout' &&
        <Fragment>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Typography variant="body1" sx={{ flex: 'auto' }} >Shipment</Typography>
            <Typography variant="body1" align="right">$ {shipmentPrice}</Typography>
          </Box>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Typography variant="body1" sx={{ flex: 'auto' }} >Taxes</Typography>
            <Typography variant="body1" align="right">$ {taxPrice}</Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', my: 2 }}>
            <Typography variant="body1" sx={{ flex: 'auto' }} >Discount</Typography>
            <Typography variant="body1" align="right">$ 0</Typography>
          </Box> */}
          {/* {!discount &&
            <Typography align="right">
              <Typography variant="caption" color="primary"
                onClick={() => { setDiscount(true) }}
                sx={{ cursor: 'pointer' }}
              >
                I have a promo code
              </Typography>
            </Typography>
          }
          {discount &&
            <Box sx={{ display: 'flex' }}>
              <TextField
                label="Enter a promo code"
                size="small"
                sx={{ flex: 'auto', mr: 1 }}
                InputLabelProps={{
                  style: { fontSize: 14 }
                }}
              />
              <Button variant="contained" size="small">Apply</Button>
            </Box>
          } */}
        </Fragment>
      }
      <Divider sx={{ marginY: 2 }} />
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h6" sx={{ flex: 'auto', fontWeight: 700 }} >Total</Typography>
        <Typography variant="h6" align="right" sx={{ fontWeight: 700 }} >$ {subTotal}</Typography>
      </Box>
      {pathname === '/my-cart' &&
        <Button component={Link} to="/checkout" variant="contained" sx={{ width: '100%', mt: 2 }} >
          Checkout
        </Button>
      }
    </Box>
  );
}

export default OrderSummary;