import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';

import colors from 'app/styles/colors';

// *Import Components
import Cart from 'app/components/Cart/Cart';
import OrderSummary from 'app/components/OrderSummary/OrderSummary';

const mapStateToProps = (state) => {
  return {
    items: state.items
  }
}

function MyCart(props) {

  const { items } = props

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      {items.length === 0 ? (
        <Box sx={{ position: 'relative', height: '80vh' }}>
          <Box sx={{ position: 'absolute', left: '50%', top: '40%', transform: 'translate(-50%,-40%)', textAlign: 'center' }}>
            <Typography variant="subtitle2" sx={{ mb: 3, color: colors.textPrimary }}>There are no items in this cart</Typography>
            <Button variant='outlined' component={Link} to="/order" color="primary" >
              continue shopping
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ py: 2, height: '80vh' }}>

          {/* ========== Cart ========== */}
          <Grid item md={7}>
            <Cart items={items} />
          </Grid>

          {/* ========== Order Summary ========== */}
          <Grid item md={5}>
            <OrderSummary items={items} />
          </Grid>

        </Grid>
      )}

    </Container>
  );
}

export default connect(mapStateToProps)(MyCart);