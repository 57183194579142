import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, FormControl, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { emailRegex } from 'app/utils/index';
import { Service } from 'app/config/service';
import { Error } from 'app/components/UI/Error';
import useAuth from 'app/hooks/useAuth';
import { Toaster } from 'app/components/UI/Toaster';

function SignIn() {

  const { signIn } = useAuth()

  // *For Form Validation
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  // *For User SignIn
  const userSignIn = async (data) => {
    setLoading(true)
    try {
      let obj = {
        grant_type: 'password',
        UserName: data.email,
        Password: data.password,
      }
      const { access_token, AccountID } = await Service.signIn(obj);
      if (access_token && AccountID) {
        const { Data } = await Service.getProfile(access_token);
        localStorage.setItem('jwt', access_token);
        localStorage.setItem('accountId', AccountID);
        localStorage.setItem('user', JSON.stringify(Data));
        signIn({ access_token })
      }
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Fragment>

      <Toaster />

      <Container>
        <Box sx={{ minHeight: 500, height: '70vh', pt: 8 }}>
          <Grid container spacing={0} justifyContent="center">
            <Grid item md={6}>
              <Typography variant="h6" sx={{ mb: 3, textAlign: 'left' }} >
                Welcome to Prime Food! Please Login.
              </Typography>
              <Box sx={{ py: 4, px: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2 }}>
                <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }} >
                  Sign In
                </Typography>
                <Typography component="form" onSubmit={handleSubmit(userSignIn)}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Email"
                      error={errors?.email?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("email", {
                        required: 'Email is required',
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email address',
                        }
                      })}
                    />
                    {errors?.email?.message && (
                      <Error message={errors?.email?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      error={errors?.password?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register("password", {
                        required: 'Password is required',
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters"
                        }
                      })}
                    />
                    {errors?.password?.message && (
                      <Error message={errors?.password?.message} />
                    )}
                  </FormControl>
                  <Typography sx={{ fontSize: 12, textAlign: 'right', my: 1 }} >New member?&nbsp;
                    <Typography
                      component={Link}
                      to="/sign-up"
                      color="primary"
                      sx={{
                        fontSize: 12,
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    >
                      SignUp
                    </Typography>
                    &nbsp;here.
                  </Typography>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                      width: '100%',
                      marginTop: 1.3,
                      fontSize: 15,
                      fontWeight: 600,
                      textTransform: 'capitalize',
                    }}
                  >
                    Sign In
                  </LoadingButton>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container >

    </Fragment >
  );
}

export default SignIn; 