import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography, } from '@mui/material'

import { Service } from 'app/config/service';

// *Import Components
import Filter from 'app/components/Filters/Filter';
import ProductCard from 'app/components/Cards/ProductCard';
import SearchBar from 'app/components/SearchBar/SearchBar';

let brandCommaSeparatedId = [];
let categoryCommaSeparatedId = [];

function Order() {

  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);

  const getBrand = async () => {
    try {
      const { Data } = await Service.getBrand()
      setBrand(Data)
    } catch (error) {
      console.log('file: Order.js => line 77 => getBrand => error', error)
    }
  }

  const getAllCategories = async () => {
    try {
      const { Data } = await Service.getAllCategories()
      setCategory(Data)
    } catch (error) {
      console.log('file: Order.js => line 77 => getBrand => error', error)
    }
  }

  const getProduct = async (type, id, isChecked) => {
    try {
      if (type === 'brand') {
        if (!isChecked) {
          const filtered = brandCommaSeparatedId.splice(id, 1)
          brandCommaSeparatedId = filtered
        } else {
          brandCommaSeparatedId.push(id)
        }
      } else if (type == 'category') {
        if (!isChecked) {
          const filtered = categoryCommaSeparatedId.splice(id, 1)
          categoryCommaSeparatedId = filtered
        } else {
          categoryCommaSeparatedId.push(id)
        }
      }
      const brandId = brandCommaSeparatedId
      const catId = categoryCommaSeparatedId
      const pageNumber = 1
      const user = localStorage.getItem('jwt')

      const { Data } = await Service.getProduct(brandId, catId, pageNumber, user);
      setProduct(Data)
    } catch (error) {
      console.log('file: Order.js => line 78 => filter => error', error)
    }
  }

  const searchFilter = async (keyWord) => {
    try {
      const { Data } = await Service.searchFilter(keyWord)
      setProduct(Data)
    } catch (error) {
      console.log('file: Order.js => line 82 => searchFilter => error', error)
    }
  }

  useEffect(() => {
    getBrand()
    getAllCategories()
    getProduct()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      {/* ========== Products ========== */}
      <Box sx={{ pb: 3 }} >

        {/* ========== Search Bar ========== */}
        <SearchBar search={() => searchFilter()} />

        <Grid container spacing={3} justifyContent="space-between" >

          {/* ========== Filter ========== */}
          <Filter brand={brand} category={category} filter={getProduct} />

          {/* ========== Product ========== */}
          <Grid item md={9}>
            {product.length > 0 ? (
              <Grid container spacing={2}>
                {product.map((item, index) => (
                  <Grid key={index} item md={3}>
                    <ProductCard product={item} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography sx={{ textAlign: 'center' }}>Product Not Found</Typography>
            )}
          </Grid>

        </Grid>

      </Box>

    </Container>
  );
}

export default Order;