import { Apis, get, post } from '.';

const querystring = require('querystring')

export const Service = {

    // *Auth
    signIn: async (obj) => {
        let result = await post(Apis.signIn, querystring.stringify(obj));
        if (result.status === 200) return result.data;
        else throw result;
    },
    signUp: async (obj) => {
        let result = await post(Apis.signUp, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },

    // getCategories: async () => {
    //     let result = await get(Apis.getCategories);
    //     if (result.status === 200) return result.data;
    //     else throw result;
    // },
    getAllCategories: async () => {
        let result = await get(Apis.getAllCategories);
        if (result.status === 200) return result.data;
        else throw result;
    },
    // getSubCategories: async () => {
    //     let result = await get(Apis.getSubCategories);
    //     if (result.status === 200) return result.data;
    //     else throw result;
    // },
    // getCategoriesSection: async () => {
    //     let result = await get(Apis.categoriesSection);
    //     if (result.status === 200) return result.data;
    //     else throw result;
    // },
    getBrand: async () => {
        let result = await get(Apis.getBrand);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProduct: async (brandId, catId, pageNumber, token) => {
        console.log('file: service.js => line 45 => getProduct: => token', token)
        let result = await get(Apis.getProduct + 'BrandID=' + brandId + '&CategoryID=' + catId + '&pageNumber=' + pageNumber + '&pageSize=20' + '&IsPagination=true', token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductDetail: async (productId, token) => {
        let result = await get(Apis.getProductDetail + 'ProductID=' + productId, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getState: async () => {
        let result = await get(Apis.getState);
        if (result.status === 200) return result.data;
        else throw result;
    },

    // *Filter
    searchFilter: async (keyWord) => {
        let result = await get(Apis.searchFilter + 'searchText=' + keyWord);
        if (result.status === 200) return result.data;
        else throw result;
    },

    // *Place Order 
    placeOrder: async (obj) => {
        let result = await post(Apis.placeOrder, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },

    // *Profile
    getProfile: async (token) => {
        let result = await get(Apis.getProfile, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateProfile: async (obj, token) => {
        let result = await post(Apis.updateProfile, obj, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getOrder: async (token) => {
        let result = await get(Apis.getOrder, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getOrderById: async (orderId, token) => {
        let result = await get(Apis.getOrderById + 'id=' + orderId, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
}