import React, { useState, useEffect, useRef } from 'react'
import { AppBar, Box, InputBase, Toolbar, Typography } from '@mui/material'
import { Search } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';

import colors from 'app/styles/colors';

function SearchBar(props) {

  const { search } = props

  const Searchbar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.black,
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      borderRadius: '5px',
      color: colors.black,
      backgroundColor: 'rgba(0, 0, 0, 0.1);',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const [searchKey, setSearchKey] = useState('');

  const textInput = useRef(null);

  const getSearchValue = (e) => {
    try {
      console.log(e.target.value.length);
      if (e.target.value.length > 3) {
        console.log(e.target.value);
        search(e.target.value)
        setSearchKey(e.target.value)
      }
    } catch (error) {
      console.log('file: SearchBar.js => line 59 => getSearchValue => error', error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      textInput.current.focus();
    }, 100);
  }, []);


  return (
    <Box sx={{ flexGrow: 1, mb: 1.3, }}>
      <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
        <Toolbar>
          <Typography
            noWrap
            color="textSecondary"
            sx={{ flexGrow: 1, fontSize: 25, fontWeight: 400 }}
          >
            All Product
          </Typography>
          <Searchbar>
            <SearchIconWrapper>
              <Search />
            </SearchIconWrapper>
            <StyledInputBase
              defaultValue={searchKey}
              onKeyUp={getSearchValue}
              ref={textInput}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Searchbar>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SearchBar;