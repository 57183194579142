import React, { useEffect } from 'react'
import { Typography, Container, Grid, CardMedia, Box, Divider, } from '@mui/material'

import colors from 'app/styles/colors';
import Images from 'assets/Images';

function About() {

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      <CardMedia
        image={Images.about}
        sx={{
          position: 'relative',
          height: 400,
          borderRadius: 1,
        }}
      >
        <Grid container spacing={0} justifyContent="center" alignItems="center"
          sx={{
            height: '100%',
            bgcolor: 'rgba(0,0,0,0.5)',
            borderRadius: 1,
          }}
        >
          <Grid item md={8} sx={{ textAlign: 'center' }}>
            <Typography variant="h2" color={colors.white} >About Us</Typography>
            <Typography variant="body1" color={colors.white} sx={{ mb: 1, mt: 2 }} >WHAT CAN WE DO FOR YOU?</Typography>
          </Grid>
        </Grid>
      </CardMedia>

      <Grid container spacing={2} sx={{ my: 6, }} >

        <Grid item md={6}>
          <Typography variant="h4" >Our Missions</Typography>
          <Divider sx={{ bgcolor: colors.primary, height: '2px', width: 120, my: 1 }} />
          <Typography variant='body1' color={colors.textPrimary} sx={{ py: 2 }} >
            At Prime Foods, we aim to be market leaders, in setting an example of great service and backing it with great quality South Asian products we carry. Our Directors travel to great lengths to find quality products to bring to our community in Alberta, Saskatchewan, and British Columbia, Manitoba, Yukon and The Northwest Territories.
          </Typography>
          <Typography variant='body1' color={colors.textPrimary} sx={{ py: 2 }} >
            Our Prices our best in class, and unmatched. We reduce our costs by ordering efficiently and regularly, all the while passing on those savings to our customers.
          </Typography>
          <Typography variant='body1' color={colors.textPrimary} sx={{ py: 2 }} >
            If you are a wholesaler, retailer, or a restaurant, Give us a call, and one of our representatives will be happy to assist you with any questions you may have.
          </Typography>
        </Grid>

        <Grid item md={6}>
          <CardMedia
            component="img"
            height="450"
            image={Images.product2}
            sx={{ borderRadius: 2.5 }}
          />
        </Grid>

      </Grid>

      <Typography
        variant="h3"
        color={colors.textPrimary}
        sx={{
          textAlign: 'center',
          py: 4,
          fontWeight: 600,
        }}
      >
        Gallery
      </Typography>

      <Box sx={{ mb: 7 }}>
        <Grid container spacing={1.5} sx={{ my: 1 }}>
          <Grid item md={5}>
            <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
              <CardMedia
                component="img"
                height="310"
                image={Images.product1}
                sx={{
                  borderRadius: 2.5,
                  '&:hover': {
                    transform: "scale(1.2)",
                    transition: "all 0.5s linear",
                    overflow: "hidden",
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={4} container spacing={1}>
            <Grid item md={12}>
              <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
                <CardMedia
                  component="img"
                  height="150"
                  image={Images.product8}
                  sx={{
                    borderRadius: 2.5,
                    '&:hover': {
                      transform: "scale(1.2)",
                      transition: "all 0.5s linear",
                      overflow: "hidden",
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
                <CardMedia
                  component="img"
                  height="150"
                  image={Images.product3}
                  sx={{
                    borderRadius: 2.5,
                    '&:hover': {
                      transform: "scale(1.2)",
                      transition: "all 0.5s linear",
                      overflow: "hidden",
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
              <CardMedia
                component="img"
                height="310"
                image={Images.product4}
                sx={{
                  borderRadius: 2.5,
                  '&:hover': {
                    transform: "scale(1.2)",
                    transition: "all 0.5s linear",
                    overflow: "hidden",
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1.5} sx={{ my: 1 }}>
          <Grid item md={7}>
            <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
              <CardMedia
                component="img"
                height="310"
                image={Images.product7}
                sx={{
                  borderRadius: 2.5,
                  '&:hover': {
                    transform: "scale(1.2)",
                    transition: "all 0.5s linear",
                    overflow: "hidden",
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5} container spacing={1}>
            <Grid item md={12}>
              <Box overflow="hidden" sx={{ borderRadius: 2.5 }}>
                <CardMedia
                  component="img"
                  height="150"
                  image={Images.product6}
                  sx={{
                    borderRadius: 2.5,
                    '&:hover': {
                      transform: "scale(1.2)",
                      transition: "all 0.5s linear",
                      overflow: "hidden",
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box overflow="hidden" sx={{ borderRadius: 2.5, }}>
                <CardMedia
                  component="img"
                  height="150"
                  image={Images.product5}
                  sx={{
                    borderRadius: 2.5,
                    '&:hover': {
                      transform: "scale(1.2)",
                      transition: "all 0.5s linear",
                      overflow: "hidden",
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

    </Container>
  );
}

export default About;