import React, { useEffect, useState } from 'react'
import { Container, Box, Divider, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button, TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'

import { Service } from 'app/config/service';
import useAuth from 'app/hooks/useAuth';
import colors from 'app/styles/colors';

// *Import Components
import SideNav from 'app/components/SideNav/SideNav';
import { Error } from 'app/components/UI/Error';

function MyProfile() {

  const { user } = useAuth()

  // *For Form Validation
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  // *For State
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  // *For Save Button Hide
  const [button, setButton] = useState(false)

  // *For Get State
  const getState = async () => {
    try {
      const { Data } = await Service.getState()
      setState(Data)
    } catch (error) {
      console.log('file: SignUp.js => line 58 => getState => error', error)
    }
  }

  // *For Get Profile 
  const getProfile = async () => {
    try {
      const { Data } = await Service.getProfile(user);
      localStorage.setItem('user', JSON.stringify(Data));
      getUserData()
    } catch (error) {
      console.log('file: MyAccount.js => line 52 => getProfile => error', error)
    }
  };

  // For Get UserDate
  const getUserData = () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'))
      setValue("name", userData.Name)
      setValue("companyName", userData.Company)
      setValue("phone", userData.Phone)
      setValue("email", userData.Email)
      setValue("address", userData.Address)
      setValue("address2", userData.Address2)
      setValue("country", userData.Country)
      setValue("city", userData.City)
      setValue("postalCode", userData.PostalCode)
      setValue("state", userData.State)
      setSelectedState(userData.State)
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 201 => getUserData => error', error)
    }
  }

  // *For Update Profile
  const updateProfile = async (data) => {
    setLoading(true)
    try {
      let obj = {
        Name: data.name,
        Company: data.companyName,
        Phone: data.phone,
        Address: data.address,
        Address2: data.address2,
        Country: data.country,
        City: data.city,
        State: data.state,
        PostalCode: data.postalCode,
      }
      const { ResponseCode, Message, Status } = await Service.updateProfile(obj, user);
      if (ResponseCode === 5004 | Status === true) {
        getProfile()
        Swal.fire({
          title: 'Success!',
          text: Message,
          icon: 'success',
        }).then(() => {
          window.location.reload(false)
        });
      }
    } catch (error) {
      console.log('file: SignUp.js => line 30 => userSignUp => error', error)
    } finally {
      setLoading(false)
    }
  };

  // *For Save Button Hide & Show
  const saveButton = () => {
    try {
      setButton(true)
    } catch (error) {
      console.log('file: settings.js => line 127 => setSaveButton => error', error)
    }
  }

  useEffect(() => {
    getState()
    getUserData()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>
      <Box sx={{ mb: 10 }}>
        <Grid container spacing={3} sx={{ py: 4, }}>
          <Grid item xs={3}>
            <SideNav path="/my-profile" />
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                p: '5px 0px 10px 20px',
                borderRadius: 2.5,
                bgcolor: colors.white,
              }}>

              <Typography variant="h5">My Profile</Typography>
              <Divider sx={{ width: '100%', my: 2 }} />

              <Typography component="form" onSubmit={handleSubmit(updateProfile)}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Name"
                        error={errors?.name?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("name", {
                          required: 'Name is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.name?.message && (
                        <Error message={errors?.name?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Company Name"
                        error={errors?.companyName?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("companyName", {
                          required: 'Company name is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.companyName?.message && (
                        <Error message={errors?.companyName?.message} />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Phone"
                        error={errors?.phone?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("phone", {
                          required: 'Phone number is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.phone?.message && (
                        <Error message={errors?.phone?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        type="email"
                        label="Email"
                        sx={{ width: '100%' }}
                        {...register("email")}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Address"
                        error={errors?.address?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("address", {
                          required: 'Address is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.address?.message && (
                        <Error message={errors?.address?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Address Line 2"
                        error={errors?.address2?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("address2")}
                        onChange={saveButton}
                      />
                      {errors?.address2?.message && (
                        <Error message={errors?.address2?.message} />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1} >
                  <Grid item md={3}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Country"
                        error={errors?.country?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("country")}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {errors?.country?.message && (
                        <Error message={errors?.country?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="City"
                        error={errors?.city?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("city", {
                          required: 'City is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.city?.message && (
                        <Error message={errors?.city?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl sx={{ width: '100%', my: 1.3 }} >
                      <InputLabel>State</InputLabel>
                      <Select
                        label="Age"
                        value={selectedState}
                        error={errors?.state?.message && (true)}
                        {...register("state", {
                          required: 'State is required',
                        })}
                        onChange={(e) => { setSelectedState(e.target.value) }}
                      >
                        {state.map((item, index) => (
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                      {errors?.state?.message && (
                        <Error message={errors?.state?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                      <TextField
                        variant="outlined"
                        label="Postal Code"
                        error={errors?.postalCode?.message && (true)}
                        sx={{ width: '100%' }}
                        {...register("postalCode", {
                          required: 'Postal Code is required',
                        })}
                        onChange={saveButton}
                      />
                      {errors?.postalCode?.message && (
                        <Error message={errors?.postalCode?.message} />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {button &&
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                      px: 4,
                      marginTop: 1.3,
                      fontSize: 15,
                      fontWeight: 600,
                      textTransform: 'capitalize',
                    }}
                  >
                    Save
                  </LoadingButton>
                }
              </Typography>
            </Box>

          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default MyProfile;