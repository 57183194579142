import React, { useEffect, useState } from 'react'
import { Button, Grid, Container, TextField, Typography, FormControl, CardMedia, Divider, } from '@mui/material'
import { useForm } from 'react-hook-form';

import { emailRegex } from 'app/utils';
import colors from 'app/styles/colors';
import Images from 'assets/Images';
import { Error } from 'app/components/UI/Error';
import { LoadingButton } from '@mui/lab';

function Contact() {

  // *For Form Validation
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [loading, setLoading] = useState(false);

  // *For Contact
  const formSubmit = async (data) => {
    setLoading(true)
    try {
      let obj = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      }
      reset()
      console.log('file: Contact.js => line 28 => formSubmit => obj', obj)
    } catch (error) {
      console.log('file: Contact.js => line 34 => formSubmit => error', error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Container>

      <Grid container spacing={0}>
        <Grid item md={8}>
          <Typography
            component="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d321199.820538494!2d-114.36801404656201!3d51.02772023569974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537170039f843fd5%3A0x266d3bb1b652b63a!2sCalgary%2C%20AB%2C%20Canada!5e0!3m2!1sen!2s!4v1641475827615!5m2!1sen!2s"
            sx={{
              width: '100%',
              height: 400,
              border: 'none',
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          />
        </Grid>
        <Grid item md={4}>
          <CardMedia image={Images.office}
            sx={{
              position: 'relative',
              height: 400,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <Grid container spacing={0} justifyContent="center" alignItems="center"
              sx={{
                height: '100%',
                bgcolor: 'rgba(0,0,0,0.6)',
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
              }}
            >
              <Grid item md={8} sx={{ textAlign: 'center' }}>
                <Typography variant="h4" color={colors.white} >Our Office</Typography>
                <Divider sx={{ bgcolor: colors.primary, height: 1, width: 120, mx: 'auto', my: 1 }} />
                <Typography variant="body1" color={colors.white} sx={{ mb: 1, mt: 2 }} > Calgary, AB Canada</Typography>
                <Typography variant="body1" color={colors.white} sx={{ my: 1 }} >    info@primefoods.ca</Typography>
                <Typography variant="body1" color={colors.white} sx={{ my: 1 }} >     1.587.433.28OO</Typography>
              </Grid>
            </Grid>
          </CardMedia>
        </Grid>
      </Grid>

      <Grid container spacing={0}
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 4, pb: 8, my: 4 }}
      >
        <Grid item md={9} sx={{ p: 7, my: 4, borderRadius: 2.5, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} >

          <Typography variant="h5" >
            Send Us Message
          </Typography>
          <Divider sx={{ bgcolor: colors.primary, height: 1, width: 100, my: 1, mb: 2 }} />

          <Typography component="form" onSubmit={handleSubmit(formSubmit)}>

            <Grid container spacing={1}>
              <Grid item md={6}>
                <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                  <TextField
                    label="Name"
                    sx={{ width: '100%' }}
                    {...register("name", {
                      required: 'Name is required',
                    })}
                  />
                  {errors?.name?.message && (
                    <Error message={errors?.name?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
                  <TextField
                    label="E-mail"
                    sx={{ width: '100%' }}
                    {...register("email", {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email address',
                      }
                    })}
                  />
                  {errors?.email?.message && (
                    <Error message={errors?.email?.message} />
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
              <TextField
                label="Subject"
                sx={{ width: '100%' }}
                {...register("subject")}
              />
            </FormControl>

            <FormControl variant="standard" sx={{ width: '100%', my: 1.3 }} >
              <TextField
                label="Your Message"
                multiline
                rows={6}
                sx={{ width: '100%' }}
                {...register("message")}
              />
            </FormControl>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              sx={{ py: 1, px: 4, display: 'block', }}
            >
              Send
            </LoadingButton>

          </Typography>

        </Grid>
      </Grid>

    </Container>
  );

}
export default Contact;