import React, { Fragment } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';

import colors from 'app/styles/colors';

function Category(props) {

  const { category } = props

  return (
    <Fragment>
      <Box
        sx={{
          width: 100,
          height: 100,
          margin: 'auto',
          borderRadius: '50%',
          backgroundColor: colors.primaryLight,
        }}
      >
        <CardMedia
          component="img"
          image={category.ImageUrl}
          alt={category.HeadCategory}
          sx={{
            width: 'auto',
            height: 100,
            margin: 'auto',
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: 14,
          color: colors.textPrimary,
          textAlign: 'center',
          mt: 1.3,
        }}
      >
        {category.HeadCategory}
      </Typography>
    </Fragment>
  );
}

export default Category; 