import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

function Modal(props) {

  const { open, onClose, guestCheckout } = props

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" >

      <DialogTitle sx={{ textAlign: 'center', width: 350, mb: 1 }} >
        How would you like to place order?
      </DialogTitle>

      <DialogContent sx={{ px: 4.5, textAlign: 'center' }} >
        <Button variant='text' color='primary' onClick={() => guestCheckout()} sx={{ boxShadow: 2, mx: 1, width: 110 }} >As Guest</Button>
        <Button component={Link} to="/sign-in" variant='contained' color='primary' sx={{ boxShadow: 2, mx: 1, width: 110 }} >Sign In</Button>
      </DialogContent>

    </Dialog>
  );
}

export default Modal; 