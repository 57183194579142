import React from 'react';
import { CardMedia } from '@mui/material';

function Brand(props) {

  const { brand } = props

  return (
    <CardMedia
      component="img"
      title={brand.BrandTitle}
      image={brand.ImageURl}
      alt="product"
      sx={{
        width: 'auto',
        height: 150,
        margin: 'auto',
      }}
    />
  );
}

export default Brand; 