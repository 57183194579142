import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Grid, CardMedia, Typography, Box, Button, IconButton, Divider, Breadcrumbs, OutlinedInput } from '@mui/material';
import { Add, Delete, NavigateNext, Remove, ShoppingCart } from '@mui/icons-material';
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from 'react-redux';
import "swiper/css";

import colors from 'app/styles/colors';
import { Service } from 'app/config/service';
import { updateCartData } from 'app/redux/Actions/ActionCreator';
import useAuth from 'app/hooks/useAuth';

// *Import Components
import ProductCard from 'app/components/Cards/ProductCard';

function ProductDetail() {

  const { category, name, id } = useParams()
  const { user } = useAuth()

  const dispatch = useDispatch();
  const addToCart = (_data) => dispatch(updateCartData(_data));

  // *For Product Detail
  const [productDetail, setProductDetail] = useState([]);

  // *For Product Quantity
  const [quantity, setQuantity] = useState('1');

  // *For Add To Cart 
  const addToCartHandler = (item, qty) => {
    let obj = {
      type: 'add',
      id: item.ProductID,
      name: item.ProductName,
      image: item.ImageURl,
      qty: qty,
      price: item.SalePrice,
      total: item.Qty * item.SalePrice,
      TaxPercent: item.Qty * item.ShipmentPrice,
      ShipmentPrice: item.Qty * item.ShipmentPrice,
    };
    addToCart(obj);
  };

  // *For Get Product Detail
  const getProductDetail = async () => {
    try {
      const { Data } = await Service.getProductDetail(id, user)
      setProductDetail(Data)
    } catch (error) {
      console.log('file: ProductDetail.js => line 63 => getProductDetail => error', error)
    }
  }

  // *For Get Product Quantity
  const getQuantity = (event) => {
    setQuantity(event.target.value === '' ? '' : Number(event.target.value));
  };

  // *For Input Blur
  const handleBlur = () => {
    if (quantity <= 0) {
      setQuantity(1);
    } else if (quantity > 50) {
      setQuantity(50);
    }
  };

  useEffect(() => {
    getProductDetail()
    window.scrollTo({ top: 0 })
  }, [id]);

  return (
    <Container>

      {/* ========== Breadcrumb ========== */}
      <Breadcrumbs sx={{ mb: 1 }} separator={<NavigateNext fontSize="small" />}>
        <Typography
          variant="body1"
          component={Link}
          to="/order"
          color={colors.primary}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              transition: '0.3s ease-in-out',
            },
          }}
        >
          Order
        </Typography>
        <Typography
          variant="body1"
          component={Link}
          to="/order"
          color={colors.primary}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              transition: '0.3s ease-in-out',
            },
          }}
        >
          {category}
        </Typography>
        <Typography variant="body1" color={colors.darkGray} >
          {name}
        </Typography>
      </Breadcrumbs>

      {/* ========== Product Detail ========== */}
      <Grid container spacing={2} sx={{ py: 2 }}>

        <Grid item md={7}>
          <CardMedia
            image={productDetail[0]?.ImageURl}
            sx={{
              height: '100%',
              backgroundSize: 'contain',
              border: '1px solid #00000059'
            }}
          />
        </Grid>

        <Grid item md={5} sx={{ pr: 4 }} >
          <Typography variant="h4" color="primary" sx={{ mb: 3 }} >
            {productDetail[0]?.ProductName}
          </Typography>
          <Box sx={{ display: 'flex', my: 1 }}>
            <Typography variant="body1" color={colors.textPrimary}>Brand : </Typography>
            <Typography component={Link} to="/" variant="body1" color={colors.textPrimary} sx={{ textDecoration: 'none', mx: 1, }}>
              {productDetail[0]?.BRandTitle}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', my: 1 }}>
            <Typography variant="body1" color={colors.textPrimary}>Category : </Typography>
            <Typography component={Link} to="/" variant="body1" color={colors.textPrimary} sx={{ textDecoration: 'none', mx: 1 }}>
              {productDetail[0]?.Category}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', my: 1 }}>
            <Typography variant="body1" color={colors.textPrimary}>Pack Size : </Typography>
            <Typography variant="body1" color={colors.textPrimary} sx={{ textDecoration: 'none', mx: 1 }}>
              {productDetail[0]?.PackSize}{productDetail[0]?.UnitCode}
            </Typography>
          </Box>
          <Typography variant="h5" color="primary" sx={{ my: 2 }}>
            $ {productDetail[0]?.SalePrice}
          </Typography>
          {/* <Divider sx={{ my: 2 }} />
            <Typography variant="body2" sx={{ flex: '50%', my: 1 }} >
              Category : <Typography component="span" variant="body1" >
                {productDetail[0]?.Category}
              </Typography>
            </Typography>
            <Typography variant="body2" sx={{ flex: '50%', my: 1 }} >
              Brand :
              <Typography component="span" variant="body1" >
                {productDetail[0]?.BRandTitle}
              </Typography>
            </Typography>
            <Typography variant="body2" sx={{ flex: '50%', my: 1 }} >
              Code : <Typography component="span" variant="body1" >Kh-23321</Typography>
            </Typography>
            <Typography variant="body2" sx={{ flex: '50%', my: 1 }} >
              Description : <Typography component="span" variant="body1" >
                {productDetail[0]?.PackSize}
              </Typography>
            </Typography> */}
          {/* <Divider sx={{ my: 2 }} /> */}
          {/* <Typography variant="h5" color="primary" >
              $ {productDetail[0]?.SalePrice}
            </Typography> */}
          {/* {quantity !== 0 &&
              <Grid container spacing={0} sx={{ mt: 3, mb: 1 }} >
                <Grid item md={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: 1,
                      border: `1px solid ${colors.primaryMedium}`,
                    }}
                  >
                    <IconButton size="small"
                      onClick={() => { decreaseQuantity() }}
                      sx={{
                        bgcolor: colors.primary,
                        borderRadius: 1,
                        color: colors.white,
                        '&:hover': {
                          bgcolor: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    >
                      {quantity === 1 &&
                        <Delete fontSize="small" />
                      }
                      {quantity !== 1 &&
                        <Remove fontSize="small" />
                      }
                    </IconButton>
                    <Typography variant='body1' component="span" sx={{ pt: 0.5 }} >{quantity}</Typography>
                    <IconButton size="small"
                      onClick={() => { increaseQuantity() }}
                      sx={{
                        bgcolor: colors.primary,
                        borderRadius: 1,
                        color: colors.white,
                        '&:hover': {
                          bgcolor: colors.primary,
                          transition: '0.3s ease-in-out',
                        },
                      }}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            } */}
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" color={colors.textPrimary} sx={{ my: 1 }}>Quantity :</Typography>
            <OutlinedInput type="number" size="small"
              value={quantity}
              onChange={getQuantity}
              onBlur={handleBlur}
              sx={{ borderRadius: '40px', ml: 1, width: 80 }}
            />
          </Box>
          <Button type="submit" variant="contained"
            size="large"
            disabled={productDetail[0]?.InStock ? true : false}
            startIcon={productDetail[0]?.InStock ? '' : <ShoppingCart />}
            onClick={() => addToCartHandler(productDetail[0], quantity)}
            sx={{
              my: 2.5,
              borderRadius: '20px',
              textTransform: 'inherit'
            }}
          >
            {productDetail[0]?.InStock ? (
              'Out of Stock'
            ) : (
              'Add to Cart'
            )}
          </Button>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" color="textPrimary">Description:</Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body2" color="textSecondary">{productDetail[0]?.Description}</Typography>
          </Box>

        </Grid>

      </Grid>

      {/* ========== Related Products ========== */}
      {/* <Box sx={{ py: 2.5 }}>
          <Typography variant="h4" component="h4"
            color={colors.textPrimary}
            align="center"
            sx={{
              my: 2.5,
            }}
          >
            Related Products
          </Typography>

          <Swiper
            className="mySwiper"
            loop={true}
            slidesPerView={5}
            spaceBetween={30}
            speed={1000}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false,
            }}
          >
            {product.map((item, index) => (
              <SwiperSlide key={index}>
                <ProductCard
                  item={item}
                />
              </SwiperSlide>
            ))}
          </Swiper>

        </Box> */}

    </Container>
  );
}

export default ProductDetail;