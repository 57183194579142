import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination"

// *Import Component
import Category from '../Categories/Category';
import ProductCard from '../Cards/ProductCard';
import Brand from '../Brands/Brand';

function Slider(props) {

  const { type, product, slideView, spaceBetween, speed, delay, pagination } = props;

  SwiperCore.use([Autoplay, Pagination]);

  return (
    <Swiper
      className="mySwiper"
      loop={true}
      slidesPerView={slideView}
      spaceBetween={spaceBetween}
      speed={speed}
      pagination={pagination === true ? { "dynamicBullets": true, "clickable": true, } : false}
      autoplay={{
        "delay": delay,
        "disableOnInteraction": false,
      }}
    >
      {product.map((item, index) => (
        <SwiperSlide key={index}>
          {type === 'category' ? <Category category={item} /> : ''}
          {type === 'product' ? <ProductCard product={item} /> : ''}
          {type === 'brand' ? <Brand brand={item} /> : ''}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slider; 