
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography, TextField, Grid, FormControl, Checkbox, FormControlLabel, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'

import { Error } from '../UI/Error';
import { emailRegex } from 'app/utils';
import { Service } from 'app/config/service';
import { RemoveCartData } from 'app/redux/Actions/ActionCreator';
import colors from 'app/styles/colors';
import useAuth from 'app/hooks/useAuth';

// *Import Components
import Modal from '../Modal/Modal';

function CheckoutStepper(props) {

  const { items } = props
  const navigate = useNavigate()
  const { user } = useAuth()
  const dispatch = useDispatch();
  const removeCartData = () => dispatch(RemoveCartData());

  // *For Form Validation
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();

  // *For Stepper
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [shipmentPrice, setShipmentPrice] = useState(0);

  // *For SignIn Dialog
  const [signInDialog, setSignInDialog] = useState(false);

  const [userInfo, setUserInfo] = useState({});
  const [userDelivery, setUserDelivery] = useState({});

  // *For State
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // *For SubTotal
  const getSubTotal = () => {
    let productAmount = 0;
    if (items.length > 0) {
      items.forEach(value => {
        const price = value.price * value.qty
        productAmount += price
        setSubTotal(productAmount)
      });
    }
  }

  const getTaxPrice = () => {
    let tax = 0;
    if (items.length > 0) {
      items.forEach(value => {
        const price = value.price * value.TaxPercent
        console.log('file: OrderSummary.js => line 36 => getTaxPrice => price', price)
        const taxTotal = price / 100
        tax += taxTotal
        setTaxPrice(tax)
      });
    }
  }

  const getShipmentPrice = () => {
    if (items.length > 0) {
      items.forEach(value => {
        setShipmentPrice(value.ShipmentPrice)
      });
    }
  }

  // *For Get State
  const getState = async () => {
    try {
      const { Data } = await Service.getState()
      setState(Data)
    } catch (error) {
      console.log('file: SignUp.js => line 58 => getState => error', error)
    }
  }

  // *For Personal Info
  const personalInfo = async (data) => {
    try {
      let obj = {
        email: data.email,
        name: data.name,
        phone: data.phone,
      }
      setUserInfo(obj)
      handleNext()
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 76 => personalInfo => error', error)
    }
  };

  // *For Delivery Address
  const deliveryDetail = async (data) => {
    try {
      let obj = {
        Address: data.address,
        Country: data.country,
        City: data.city,
        PostalCode: data.postalCode,
        State: data.state,
      }
      setUserDelivery(obj)
      handleNext()
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 93 => deliveryDetail => error', error)
    }
  };

  // *For Get UserDate
  const getUserData = () => {
    try {
      if (user) {
        const userData = JSON.parse(localStorage.getItem('user'))
        setValue("name", userData.Name)
        setValue("phone", userData.Phone)
        setValue("email", userData.Email)
        setValue2("address", userData.Address)
        setValue2("country", userData.Country)
        setValue2("city", userData.City)
        setValue2("postalCode", userData.PostalCode)
        setValue2("state", userData.State)
        setSelectedState(userData.State)
      }
    } catch (error) {
      console.log('file: CheckoutStepper.js => line 201 => getUserData => error', error)
    }
  }

  const placeOrder = async (guestCheckout) => {
    setLoading(true)
    try {
      let data = {};
      let itemsList = [];

      for (const index in items) {
        const { id, price, qty, TaxPercent } = items[index];
        let newItem = {
          ProductID: id,
          Qty: qty,
          SalePrice: price,
          Total: price * qty,
          TaxPercent: TaxPercent,
          TaxAmount: (TaxPercent * price) / 100,
        };
        itemsList.push(newItem);
      }

      data = {
        OrderDate: new Date().toLocaleString(),
        TotalAmount: subTotal,
        OrderMode: 'Web',
        PaymentMode: 'COD',
        OrderDetails: itemsList,
        TaxAmount: taxPrice,
        ShipmentCharges: shipmentPrice,
      }

      if (user) {
        const accountId = localStorage.getItem('accountId')
        data = {
          ...data,
          AccountID: accountId,
        }
      } else if (guestCheckout) {
        data = {
          ...data,
          AccountID: 33,
          GuestProfile: {
            Name: userInfo.name,
            Email: userInfo.email,
            Cell: userInfo.phone,
            Address: userDelivery.address,
            City: userDelivery.city,
            Province: userDelivery.country,
          },
        }
      } else {
        setSignInDialog(true)
        return
      }

      const { Data, ResponseCode, Status, Message } = await Service.placeOrder(data);
      if (ResponseCode === 4001 | Status === true) {
        setSignInDialog(false)
        removeCartData()
        Swal.fire({
          title: 'Order # ' + Data,
          text: 'Your order has been placed successfully!',
          icon: 'success',
        }).then(() => {
          navigate('/order')
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: Message,
          icon: 'error',
        })
      }
    } catch (error) {
      console.log('file: OrderSummary.js => line 39 => checkout => error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubTotal()
    getTaxPrice()
    getShipmentPrice()
    getState()
    getUserData()
  }, []);

  return (
    <Fragment>

      {/* ========== Checkout Modal ========== */}
      <Modal open={signInDialog} onClose={() => setSignInDialog(false)} guestCheckout={() => placeOrder('guest')} />

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>
            Personal Info
          </StepLabel>
          <StepContent sx={{ pt: 2 }}>
            <Typography component="form" onSubmit={handleSubmit(personalInfo)}>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                <TextField
                  variant="outlined"
                  type="email"
                  label="Email"
                  error={errors?.email?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("email", {
                    required: 'Email is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Please enter a valid email address',
                    }
                  })}
                />
                {errors?.email?.message && (
                  <Error message={errors?.email?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                <TextField
                  variant="outlined"
                  label="Name"
                  error={errors?.name?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("name", {
                    required: 'name is required',
                  })}
                />
                {errors?.name?.message && (
                  <Error message={errors?.name?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                <TextField
                  variant="outlined"
                  label="Phone"
                  error={errors?.phone?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register("phone", {
                    required: 'phone is required',
                  })}
                />
                {errors?.phone?.message && (
                  <Error message={errors?.phone?.message} />
                )}
              </FormControl>
              <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} >
                Next
              </Button>
            </Typography>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            Delivery Detail
          </StepLabel>
          <StepContent sx={{ pt: 2 }}>
            <Typography component="form" onSubmit={handleSubmit2(deliveryDetail)}>
              <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                <TextField
                  variant="outlined"
                  label="Delivery Address"
                  error={errors2?.address?.message && (true)}
                  sx={{ width: '100%' }}
                  {...register2("address", {
                    required: 'Address is required',
                  })}
                />
                {errors2?.address?.message && (
                  <Error message={errors2?.address?.message} />
                )}
              </FormControl>
              <Grid container spacing={1} >
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                    <TextField
                      variant="outlined"
                      label="Country"
                      error={errors2?.country?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register2("country", {
                        required: 'Country is required',
                      })}
                    />
                    {errors2?.country?.message && (
                      <Error message={errors2?.country?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                    <TextField
                      variant="outlined"
                      label="City"
                      error={errors2?.city?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register2("city", {
                        required: 'City is required',
                      })}
                    />
                    {errors2?.city?.message && (
                      <Error message={errors2?.city?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} >
                <Grid item md={6}>
                  <FormControl sx={{ width: '100%', my: 1.3 }} >
                    <InputLabel>State</InputLabel>
                    <Select
                      label="Age"
                      value={selectedState}
                      error={errors2?.state?.message && (true)}
                      {...register2("state", {
                        required: 'State is required',
                      })}
                      onChange={(e) => { setSelectedState(e.target.value) }}
                    >
                      {state.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                    {errors2?.state?.message && (
                      <Error message={errors2?.state?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="standard" sx={{ width: '100%', my: 1.5 }} >
                    <TextField
                      variant="outlined"
                      label="Post Code"
                      error={errors2?.postalCode?.message && (true)}
                      sx={{ width: '100%' }}
                      {...register2("postalCode", {
                        required: 'Postal Code is required',
                      })}
                    />
                    {errors2?.postalCode?.message && (
                      <Error message={errors2?.postalCode?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} >
                Next
              </Button>
            </Typography>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            Payment Detail
          </StepLabel>
          <StepContent>
            <Box sx={{ border: `2px solid ${colors.textPrimary}`, py: 0.5, px: 2, my: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked="false" sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} />}
                  label="Cash on Delivery"
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: 20,
                      color: colors.textPrimary
                    }
                  }}
                />
              </FormGroup>
            </Box>
            <Box sx={{ mb: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                onClick={() => placeOrder()}
                sx={{ mt: 1, mr: 1 }}
              >
                Place Order
              </LoadingButton>
              <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }} >
                Back
              </Button>
            </Box>
          </StepContent>
        </Step>
      </Stepper>

    </Fragment >
  );
}

export default CheckoutStepper;