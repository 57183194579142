import Axios from '../../axios';
import { errorHandler } from './errorHandler';

export const Apis = {

    // *Auth
    signIn: 'token',
    signUp: 'api/Account/Register',

    // getCategories: 'api/Categories/GetHeadCategories',
    getAllCategories: 'api/Categories/GetAllCategories',
    // getSubCategories: 'api/Categories/GetSubCategories',
    // categoriesSection: 'api/Categories/GetSections',
    getBrand: 'api/Brands/GetBrands',
    getProduct: 'api/Products/GetProducts?',
    getProductDetail: 'api/Products/GetProductDetailByID?',
    getState: 'api/States/GetStates',

    // *Filter
    searchFilter: 'api/Products/GetProductsByText?',

    // *Place Order
    placeOrder: 'api/OnlineOrders/SaveOrder',

    // *Profile
    getProfile: 'api/Customers/GetCustomerProfile',
    updateProfile: 'api/Customers/UpdateProfile',
    getOrder: 'api/OnlineOrders/GetMyOrders',
    getOrderById: 'api/OnlineOrders/GetOrderByID?',
};

export const headers = {
    'content-type': 'application/json',
};

export const get = async (endPoint, token) => {
    console.log('file: index.js => line 37 => get => endPoint', endPoint)
    console.log('file: index.js => line 37 => get => token', token)
    try {
        const result = await Axios.get(endPoint, { 'headers': { 'Authorization': `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await Axios.post(endPoint, data, { 'headers': { 'Authorization': `Bearer ${token}` } })
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const put = async (endPoint, data, token) => {
    try {
        const result = await Axios.put(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};