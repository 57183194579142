const Images = {

  // *Logo
  Logo: require('./images/prime-food.png'),
  soldOut: require('./images/sold-out.png'),

  // *Animations
  orderAnimation: require('./animations/place-order.gif'),
  loading: require('./animations/loading.gif'),

  // *Banners
  banner1: require('./images/banner-1.jpg'),
  banner2: require('./images/banner-2.webp'),
  banner3: require('./images/banner-3.png'),
  banner4: require('./images/banner-4.jpg'),

  //*Prime-product
  product1: require('./images/prime-product.jpg'),
  product2: require('./images/prime-product1.jpg'),
  product3: require('./images/prime-product2.jpg'),
  product4: require('./images/prime-product3.jpg'),
  product5: require('./images/prime-product4.jpg'),
  product6: require('./images/prime-product5.jpg'),
  product7: require('./images/prime-product6.jpg'),
  product8: require('./images/prime-product7.jpg'),
  product9: require('./images/prime-product8.jpg'),
  product10: require('./images/prime-product9.jpg'),

  // *Contact
  office: require('./images/office.jpg'),

  // *About
  about: require('./images/about.jpg'),

};

export default Images;