import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Description, Logout, PersonRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import colors from 'app/styles/colors';
import useAuth from 'app/hooks/useAuth';

const useStyles = makeStyles({
  active: {
    backgroundColor: colors.primary,
    color: colors.white,
    '&>.MuiSvgIcon-root': {
      cursor: 'pointer',
      color: colors.white,
    },
  },
});

function SideNav(props) {

  const { path } = props

  const classes = useStyles()
  const navigate = useNavigate()
  const { signOut } = useAuth()

  const sideNav = [
    { icon: <PersonRounded color='primary' sx={{ fontSize: 25, verticalAlign: 'bottom', mr: 1 }} />, label: 'My Profile', path: '/my-profile' },
    { icon: <Description color='primary' sx={{ fontSize: 25, verticalAlign: 'bottom', mr: 1 }} />, label: 'My Order', path: '/my-order' },
  ]

  return (
    <Box sx={{ py: 3, bgcolor: colors.white, boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', }} >
      {sideNav.map((item, index) => (
        <Box key={index}
          onClick={() => { navigate(item.path) }}
          className={clsx({
            [classes.active]: path === item.path,
          })}
          sx={{
            p: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
            '&:hover': {
              bgcolor: colors.primary,
              color: colors.white,
              cursor: 'pointer',
              '.MuiSvgIcon-root': {
                cursor: 'pointer',
                color: colors.white,
              },
            },
          }}>
          {item.icon}
          <Typography variant="body2" component="span" >{item.label}</Typography>
        </Box>
      ))}
      <Box
        onClick={() => { signOut() }}
        sx={{
          p: 2,
          '&:hover': {
            bgcolor: colors.primary,
            color: colors.white,
            cursor: 'pointer',
            '.MuiSvgIcon-root': {
              cursor: 'pointer',
              color: colors.white,
            },
          },
        }}>
        <Logout color='primary' sx={{ fontSize: 25, verticalAlign: 'bottom', mr: 1 }} />
        <Typography variant="body2" component="span" >Sign Out</Typography>
      </Box>
    </Box>
  );
}

export default SideNav;